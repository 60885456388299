import { fromJS, Map } from 'immutable';
import flamelink from 'flamelink';
import firebase from './firebase';

export const fl = flamelink({ firebaseApp: firebase });

const DEFAULT_CONTENT = new Map();

// Actions
const ACTIONS = {
  UPDATE_CONTENT: 'Content:Update',
};

const updateContent = content => ({
  type: ACTIONS.UPDATE_CONTENT,
  content,
});

// Reducer
export const ContentReducer = (state = DEFAULT_CONTENT, action) => {
  switch (action.type) {
  case ACTIONS.UPDATE_CONTENT:
    return state.merge(fromJS(action.content));
  default:
    return state;
  }
};

// Selectors
const getContent = (state, schema) => state.get(schema);
const getContentBySlug = (state, schema, slug) => {
  const content = getContent(state, schema);
  if (!content) return undefined;
  return content.filter(c => c.get('slug') === slug).first();
};
const getContentByID = (state, schema, id) => {
  const content = getContent(state, schema);
  if (!content) return undefined;
  return content.get(String(id));
};

export const ContentSelectors = {
  getContent,
  getContentBySlug,
  getContentByID,
};

// eslint-disable-next-line no-console
const logFlamelinkError = error => console.log('flamelink error: ', error);

// Init
const Flamelink = {
  init(store) {
    try {
      ['homeCopy', 'teamCopy', 'servicesCopy', 'privacyCopy', 'cookieCopy', 'pages', 'snippets', 'teammates', 'clients'].forEach((schema) => {
        fl.content.get(schema)
          .then((records) => {
            const content = {};
            content[schema] = records;
            store.dispatch(updateContent(content));
          })
          .catch(logFlamelinkError);
      });
    } catch (error) {
      logFlamelinkError(error);
    }
  },
};

export default Flamelink;
