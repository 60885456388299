import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { TextField, Select } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createTextMask } from 'redux-form-input-masks';

import FormSelect from './form_select';
import theme from '../theme';

const FieldTheme = createMuiTheme({
  palette: {
    primary: {
      light: theme.color.primary,
      main: theme.color.primary,
      dark: theme.color.textLight,
    },
    type: 'dark',
  },
});

const statesDropdownSelect = [
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AS', 'American Samoa'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['DC', 'District Of Columbia'],
  ['FM', 'Federated States Of Micronesia'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['GU', 'Guam'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MH', 'Marshall Islands'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['MP', 'Northern Mariana Islands'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PW', 'Palau'],
  ['PA', 'Pennsylvania'],
  ['PR', 'Puerto Rico'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VI', 'Virgin Islands'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
];

// Example Implementation
// <FormSelect
//  name="state"
//  options={new List(states)}
//  />

// Input Text Masks
// https://renato-bohler.github.io/redux-form-input-masks/#/

const phoneMask = createTextMask({
  pattern: '(999) 999-9999',
});

const zipCodeMask = createTextMask({
  pattern: '99999-9999',
});

// Date Time Picker Render Helpers
// https://material-ui-pickers.firebaseapp.com/
// * These forms inputs remove native datepicker functionality
// * Formating of Date is Rails compatible
// * Formating occurs during save into redux store

const renderDatePicker = (props) => {
  const {
    showErrorsInline,
    input: { onChange, value },
    meta: { touched, error },
    id,
    ...others
  } = props;

  const showError = showErrorsInline || touched;

  return (
    <DatePicker
      id={id}
      keyboard
      clearable
      format="MM-dd-yyyy"
      placeholder="MM-DD-YYYY"
      mask={val => (val ? [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] : [])}
      error={!!(showError && error)}
      helperText={showError && error}
      value={value || null}
      onChange={onChange}
      fullWidth
      {...others}
    />
  );
};

const renderDateTimePicker = (props) => {
  const {
    showErrorsInline,
    input: { onChange, value },
    meta: { touched, error },
    id,
    ...others
  } = props;

  const showError = showErrorsInline || touched;

  return (
    <DateTimePicker
      id={id}
      keyboard
      clearable
      format="MM-dd-yyyy hh:mm a"
      placeholder="MM-DD-YYYY HH:MM A"
      // eslint-disable-next-line no-shadow
      mask={value => (value ? [
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        ':',
        /\d/,
        /\d/,
        ' ',
        /a|p/i,
        'M'] : []
      )}
      error={!!(showError && error)}
      helperText={showError && error}
      value={value || null}
      onChange={onChange}
      fullWidth
      {...others}
    />
  );
};

export {
  Field,
  reduxForm,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormSelect,
  FieldTheme,
  MuiThemeProvider,
  DatePicker,
  createTextMask,
  statesDropdownSelect,
  phoneMask,
  zipCodeMask,
  renderDatePicker,
  renderDateTimePicker,
};
