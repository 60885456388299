const ACTIONS = {
  TOGGLE_CLIENT_GRID_LIMIT: 'Client:Grid:Toggle',
  TOGGLE_CLIENT_GRID_BUTTON: 'Client:Grid:Button',
  SHOW_CLIENT_GRID_ALL: 'Client:Grid:All',
};

export const toggleClientGridLimit = () => ({
  type: ACTIONS.TOGGLE_CLIENT_GRID_LIMIT,
});

export const ShowClientGridAll = () => ({
  type: ACTIONS.SHOW_CLIENT_GRID_ALL,
});

export const toggleClientGridButton = () => ({
  type: ACTIONS.TOGGLE_CLIENT_GRID_BUTTON,
});

export default ACTIONS;
