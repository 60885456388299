import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import RootStyles, { Hero } from './root_styles';
import { Block, BlockHeading } from './block';
import Header from './header';
import Footer from './footer';
import Logo from './logo';

const PrivacyView = ({ privacy }) => (
  <RootStyles>
    <Hero className="HeroAutoHeight">
      <Header>
        <Logo />
      </Header>
      <div className="HeroContent">
        <div className="HeroContentInner HeroContentInnerWide">
          {privacy.get('heroHeading') && (
            <h1>{privacy.get('heroHeading')}</h1>
          )}
          {privacy.get('heroSubHeading') &&(
            <h2>{privacy.get('heroSubHeading')}</h2>
          )}
        </div>
      </div>
    </Hero>

    <Block size="full">
      <Block padding>
        <BlockHeading className="heading">{ privacy && privacy.get('contentHeading') }</BlockHeading>
        {privacy && <div dangerouslySetInnerHTML={{ __html: privacy.get('contentDescription') }} />}
      </Block>
    </Block>

    <Footer />
  </RootStyles>
);

PrivacyView.propTypes = { privacy: PropTypes.shape({}) };
PrivacyView.defaultProps = {
  privacy: new List(),
};

export default PrivacyView;
