import React from 'react';
import PropTypes from 'prop-types';
import { fl } from '../vendor/flamelink';

export default class FlamelinkImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: null };
  }

  componentDidMount() {
    const { id } = this.props;
    fl.storage.getURL(id).then((url) => {
      this.setState({ url });
    });
  }

  render() {
    const { width, height } = this.props;
    const { url } = this.state;
    return (
      <div
        className="img"
        style={{
          display: 'inline-block',
          backgroundImage: `url(${url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          height,
          width,
        }}
      />
    );
  }
}

FlamelinkImage.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};
