import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import FlamelinkImage from '../util/flamelink_image';
import { Block } from './block';
import RootStyles, { Hero, HeroBG } from './root_styles';
import ContactBlock from './contact_block';
import Header from './header';
import Footer from './footer';

const TeamView = ({ team, teammates }) => {

  return (
    <RootStyles>
      <Hero size="small">
        <Header theme="dark" />
        <div className="HeroContent">
          <div className="HeroContentCenter">
            {team.get('heroHeading') && (
              <h1>{team.get('heroHeading')}</h1>
            )}
            {team.get('heroSubHeading') &&(
              <h2>{team.get('heroSubHeading')}</h2>
            )}
          </div>
        </div>
        <HeroBG size="small" style={{ backgroundImage: 'url("/images/hero_bg_1.jpg")' }} />
      </Hero>

      <Block className="team" size="full" padding>
        <div className="team-inner">
          <div className="team-photos">
            { teammates.valueSeq().sortBy(teammate => teammate.get('order')).map(teammate => (
              <a href={teammate.get('linkedInUrl')} className="teammate" key={teammate} target="_blank">
                <FlamelinkImage id={teammate.get('avatar').first()} />
                <h3 className="teammate-name">{ teammate.get('name') }</h3>
                <span className="teammate-role">{ teammate.get('title') }</span>
              </a>
            )) }

          </div>
        </div>
      </Block>

      <ContactBlock />
      <Footer />
    </RootStyles>
  )
};

TeamView.defaultProps = { 
  team: new List(),
  teammates: new List(),
};
TeamView.propTypes = { 
  team: PropTypes.shape({}),
  teammates: PropTypes.shape([{}]),
};

export default TeamView;
