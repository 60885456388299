import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HeaderNav from './header_nav';
import HeaderStyles from './header_styles';
import Logo from '../logo';

const Header = ({ theme }) => (
  <HeaderStyles theme={theme}>
    <Link to="/"><Logo theme={theme} /></Link>
    {/* <HeaderNav /> */}
  </HeaderStyles>
);

Header.defaultProps = { theme: 'dark' };
Header.propTypes = { theme: PropTypes.string };

export default Header;
