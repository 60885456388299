import axios from 'axios';

// Configure Axios for our application
axios.interceptors.request.use((config) => {
  const newConf = config;
  // Add the CSRF token
  if (document.head.querySelector('meta[name="csrf-token"]')) {
    newConf.headers['X-CSRF-Token'] = document.head.querySelector('meta[name="csrf-token"]').content;
  }
  // Default to JSON and nothing else!
  newConf.headers['Content-Type'] = 'application/json';
  newConf.headers.Accept = 'application/json';
  newConf.responseType = 'json';
  return config;
});

export const updateCurrentUser = props => axios.post('/update-current-user', { user: props });

export const listWidgets = () => axios.get('/widgets');
export const updateWidget = (uid, props) => axios.patch(`/widgets/${uid}`, { widget: props });
