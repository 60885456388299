import styled from 'styled-components';
// import theme from '../theme';

const ClientGridStyles = styled.div`

  [data-unlimited='true'] {
    .experienceGrid:after {
      display: none;
    }
    .experienceGridToggle:after {
      content: 'Hide the full list';
    }
  }
  .experienceGridToggle {
    &:after {
      content: 'See the full list';
    }
  }
`;

export default ClientGridStyles;
