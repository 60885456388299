import styled from 'styled-components';
import device from '../../common/media_queries';
import theme from '../../common/theme';

const LogoStyles = styled.div`
  display: block;
  width: 200px;

  @media ${device.tablet} {
    width: 20vw;
  }

  svg {
    pointer-events: none;

    path {
      fill: ${props => (props.theme === 'light' ? theme.color.textLight : theme.color.textDark)}
    }
  }
`;

export default LogoStyles;
