import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import FlamelinkImage from '../util/flamelink_image';
import RootStyles, {
  Hero, HeroBG, Content,
} from './root_styles';
import { Block, BlockHeading } from './block';
import ContactBlock from './contact_block';
import Header from './header';
import Footer from './footer';
import ClientGrid from './client_grid';

const RootView = ({ home, teammates }) => (
  <RootStyles>
    <ParallaxProvider>
      <Hero>
        <Header theme="dark" />
        <div className="HeroContent">
          <div className="HeroContentInner">
            {home.get('heroHeading') && (
              <h1>{home.get('heroHeading')}</h1>
            )}
            {home.get('heroSubHeading') &&(
              <h2>{home.get('heroSubHeading')}</h2>
            )}
          </div>
        </div>

        <HeroBG>
          <div className="bgWrapper">
            <Parallax className="bg1" y={[-35, 25]} tagOuter="div">
              <img src="/images/hero_bg_1.jpg" alt="" />
            </Parallax>
            <Parallax className="bg2" y={[-10, 5]} tagOuter="div">
              <img src="/images/hero_bg_2.png" alt="" />
            </Parallax>
          </div>
        </HeroBG>

      </Hero>

      <Block className="sectionWho" size="full">
        <Block className="sectionInner" padding>
          <BlockHeading className="heading">{ home && home.get('whoHeading') }</BlockHeading>
          {home && <div className="sectionWho-description" dangerouslySetInnerHTML={{ __html: home.get('whoDescription') }} />}
        </Block>
        <img className="sectionBG" src="images/bg-forest.jpg" alt="" />
      </Block>

      <Block className="sectionHow" size="full" padding>
        <BlockHeading className="heading">{ home && home.get('howHeading') }</BlockHeading>
        <Block size="full" wrapper>
          <Block size="half">
            <div className="sectionHowContent">
              {home && <div className="sectionHow-description" dangerouslySetInnerHTML={{ __html: home.get('howDescription') }} />}
            </div>
          </Block>
          <Block size="half">
            <img className="sectionImage" src="images/how-image.png" alt="" />
          </Block>
        </Block>
      </Block>

      <Block className="team" size="full" padding>
        <div className="team-inner">
          <div className="team-photos">
            { teammates.valueSeq().sortBy(teammate => teammate.get('order')).map(teammate => (
              <a href={teammate.get('linkedInUrl')} className="teammate" key={teammate} target="_blank">
                <FlamelinkImage id={teammate.get('avatar').first()} />
                <h3 className="teammate-name">{ teammate.get('name') }</h3>
                <span className="teammate-role">{ teammate.get('title') }</span>
              </a>
            )) }

          </div>
        </div>
      </Block>

      <Block className="experience" size="full">
        <Content>
          <BlockHeading align="center">{ home && home.get('experienceHeading') }</BlockHeading>

          <ClientGrid maxItems="12" />

        </Content>
      </Block>

      <ContactBlock />
      <Footer />
    </ParallaxProvider>
  </RootStyles>
);

RootView.defaultProps = {
  home: new List(),
  page: undefined,
  teammates: new List(),
};

RootView.propTypes = {
  home: PropTypes.shape({}),
  page: PropTypes.shape({ get: PropTypes.func }),
  teammates: PropTypes.shape([{}]),
  toggleClientGridLimit: PropTypes.func.isRequired,
};

export default RootView;
