import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import FlamelinkImage from '../util/flamelink_image';
import RootStyles, {
  Hero, HeroBG, Content,
} from './root_styles';
import Block, {BlockHeading} from './block';
import Header from './header';
import Footer from './footer';
import Logo from './logo';

const propTypes = {
  cookie: PropTypes.shape({}),
};

const defaultProps = {
  cookie: new List(),
  page: undefined,
};

const CookieView = ({
  cookie,
}) => (
  <RootStyles>
    <Hero className="HeroAutoHeight">
      <Header>
        <Logo />
      </Header>
      <div className='HeroContent'>
        <div className='HeroContentInner HeroContentInnerWide'>
          <h1>{ cookie && cookie.get('heroHeading') }</h1>
          <h2>{ cookie && cookie.get('heroSubHeading') }</h2>
        </div>
      </div>
    </Hero>

    <Block size='full'>
      <Block padding>
        <BlockHeading className='heading'>{ cookie && cookie.get('contentHeading') }</BlockHeading>
        {cookie && <div dangerouslySetInnerHTML={{ __html: cookie.get('contentDescription') }} />}
      </Block>
    </Block>

    <Footer />
  </RootStyles>
);

CookieView.propTypes = propTypes;
CookieView.defaultProps = defaultProps;

export default CookieView;
