import actions from '../actions';
import UserModel from '../../common/models/user_model';

const DEFAULT_STATE = new UserModel();

// This reducer is responsible for tracking the identity of the user
function UserReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
  case actions.RECOGNIZE_USER:
    return new UserModel(action.user);
  default:
    return state;
  }
}

export default UserReducer;
