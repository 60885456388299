// List of session-related actions.  All scoped with 'SESSION:'
const ACTIONS = {
  RECOGNIZE_USER: 'SESSION:RECOGNIZE_USER',
  SHOW_FLASH: 'SESSION:SHOW_FLASH',
  DISMISS_FLASH: 'SESSION:DISMISS_FLASH',
  SET_EXPERIMENT: 'SESSION:EXPERIMENT',
};

// Set the current user
export const recognizeUser = user => ({
  type: ACTIONS.RECOGNIZE_USER,
  user,
});

// Display a new flash message
export const showFlash = flash => ({
  type: ACTIONS.SHOW_FLASH,
  flash,
});

// Dismiss a flash message by id
export const dismissFlash = flashId => ({
  type: ACTIONS.DISMISS_FLASH,
  flashId,
});

export const setTreatment = (key, treatment) => ({
  type: ACTIONS.SET_EXPERIMENT,
  key,
  treatment,
});

export default ACTIONS;
