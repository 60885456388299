import React from 'react';
// import PropTypes from 'prop-types';
import ContactFormStyles from './contact_form_styles';
import {
  Field, reduxForm, TextField, FieldTheme, MuiThemeProvider,
} from '../../../common/form/form';

const propTypes = {
  // prop: PropTypes.string,
};

const defaultProps = {
  // prop: 'ContactForm View',
};

const validate = (values) => {
  const errors = {};
  if (!values.get('email')) {
    errors.email = 'required';
  }
  return errors;
};

const ContactForm = ({
  // eslint-disable-next-line react/prop-types
  handleSubmit, status,
}) => (
  <ContactFormStyles>
    { status === 'success' ? (
      <div className="status">
        Thank you for contacting us! We will be in touch soon.
      </div>
    ) : (
      <MuiThemeProvider theme={FieldTheme}>

        <form id="contactForm" action="#thank-you" method="post" onSubmit={handleSubmit}>
          { status === 'error' && (
            <div>Sorry, Something went wrong...</div>
          )}
          <div className="row">
            <div className="col-sm-3" style={{ marginBottom: '10px' }}>
              <Field component={TextField} label="Name" name="Name" fullWidth />
            </div>
            <div className="col-sm-4" style={{ marginBottom: '10px' }}>
              <Field component={TextField} label="Email" name="Email" type="email" fullWidth required />
            </div>
            <div className="col-sm-4" style={{ marginBottom: '10px' }}>
              <Field component={TextField} label="Message" name="Message" fullWidth multiline rows={1} rowsMax={4} />
            </div>
            <div className="col-sm-2">
              <input type="submit" className="btn primary" value="Let’s Talk" />
            </div>
          </div>
        </form>
      </MuiThemeProvider>
    )}
  </ContactFormStyles>
);

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

const ContactFormView = reduxForm({ form: 'ContactForm', validate, enableReinitialize: true })(ContactForm);

export default ContactFormView;
