import React from 'react';
import { Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router/immutable';
import ErrorBoundary from './util/error_boundary';
import './app.css';
import theme from './common/theme';
import Root from './root';
import Privacy from './root/privacy_container';
import Services from './root/services_container';
import Team from './root/team_container';
import Cookie from './root/cookie_container';
import configureStore from './store';
import Flamelink from './vendor/flamelink';

const { store, history } = configureStore();

Flamelink.init(store);

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Switch>
              <Route exact path="/" component={Root} />
              <Route path="/team" component={Team} />
              <Route path="/services" component={Services} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/cookie" component={Cookie} />
              <Route component={Root} />
            </Switch>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default App;
