import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/media_queries';

const ContactFormStyles = styled.div`
  margin: 40px 0;


  [class*='MuiFormLabel-focused'] {
    color: ${theme.color.gold} !important;
  }

  [class*='MuiInputBase-focused'] {
    &:before {
      border-bottom: 1px solid ${theme.color.gold} !important;
    }
    &:after {
      border-bottom: 2px solid ${theme.color.gold} !important;
    }
  }

  .btn {
    background-color: transparent;
    background-size: cover;
    background-position: center;
    border: none;
    border-bottom: 4px solid ${theme.color.gold};
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: ${theme.font.sansSerif};
    font-size: 1.4rem;
    float: none;
    line-height: 1.4rem;
    padding: 0.5rem 5rem 0.7rem 0;
    position: relative;
    text-transform: uppercase;

    &:hover {
      background-color: ${theme.color.gold};
      color: ${theme.color.textLight};
    }
  }

  h2 {
    margin-bottom: 10px;
  }
`;

export default ContactFormStyles;
