import React from 'react';
import PropTypes from 'prop-types';
import Rollbar from 'rollbar';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: `${error}` };
  }

  componentDidCatch(error, info) {
    try {
      // log to Rolllbar if we can...
      Rollbar.error(`Exception rendering ${this.componentName}`, error, info);
    } catch (e) {
      // if rollbar fails, log to console
      console.log(e); // eslint-disable-line no-console
      console.log(error); // eslint-disable-line no-console
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error_boundary">
          <h1>We are sorry, but something has gone horribly wrong...</h1>
          <pre style={{ display: 'none' }}>{ hasError }</pre>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
