const theme = {
  color: {
    // OLD COLORS
    primary: '#221E1B',
    secondary: '#B39D64',
    secondaryLight: '#CAB06A',
    secondaryDark: '#A29061',

    black: '#212827',
    blackOffset: '#374140',
    gold: '#B39D64',
    blue: '#487588',
    silver: '#BAC4C4',
    copper: '#936242',
    green: '#4D8848',
    
    text: {},

    action: '#B39D64',
    actionHover: '#CAB06A',
    disabled: '#CCCCCC',
    whiteOffset: '#F0F3F3',
    grayLighter: '#CCCCCC',
    textLight: '#ffffff',
    textDark: '#221E1B',
    textAction: '#B39D64',
    textActionHover: '#CAB06A',
    bgDark: '#212827',
    white: '#ffffff',
  },
  font: {
    sansSerif: '"Expressway Soft W00 Book", "Proxima Nova", sans-serif',
    serif: '"Garamond Prmr W01 Roman", serif',
    mono: '"Source Code Pro", monospace',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
  },
  button: {
    borderRadius: 3,
  },
  screen: {

  },
  section: {

  },
  padding: {
    small: '1rem',
    base: '2rem',
    large: '5rem',
  },
  siteWidth: '83vw',
};

theme.color.text.darker = theme.color.text.black;
theme.color.text.dark = theme.color.blackOffset;
theme.color.text.light = theme.color.whiteOffset;
theme.color.text.lighter = theme.color.white;

export default theme;
