import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ClientGridStyles from './client_grid_styles';
import FlamelinkImage from '../../util/flamelink_image';

const propTypes = {
  clients: PropTypes.shape([{}]),
  gridLimit: PropTypes.array,
  toggleClientGridLimit: PropTypes.func.isRequired,
  toggleClientGridButton: PropTypes.func.isRequired,
};

const defaultProps = {
  clients: new List(),
  gridLimit: 12,
  gridButton: false,
};

const ClientGridView = ({
  clients, gridLimit, gridButton, toggleClientGridLimit, toggleClientGridButton,
}) => (
  <ClientGridStyles>
    <div data-unlimited={gridButton}>
      <div className="experienceGrid">
        { clients.valueSeq().sortBy(client => client.get('order')).slice(0, gridLimit).map(client => (
          <div key={client} className={client.get('logoImage') == null ? 'experienceItem text' : 'experienceItem'}>
            { client.get('logoImage') == null ? (<div>{client.get('clientName')}</div>) : <FlamelinkImage id={client.get('logoImage').first()} alt={client.get('clientName')} /> }
          </div>
        )) }
      </div>
      <div className="experienceGridToggle" onClick={() => { toggleClientGridLimit(); toggleClientGridButton(); }} />
    </div>
  </ClientGridStyles>
);

ClientGridView.propTypes = propTypes;
ClientGridView.defaultProps = defaultProps;

export default ClientGridView;
