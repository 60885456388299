import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router/immutable';
import { Map } from 'immutable';
import rootReducer from './reducer';

const history = createBrowserHistory();

const middleware = [
  applyMiddleware(routerMiddleware(history), thunk),
];

if (process.env.NODE_ENV !== 'production') {
  if (window.devToolsExtension) {
    middleware.push(window.devToolsExtension());
  }
}

const createStoreWithMiddleware = compose(...middleware)(createStore);

export function store(initialState = new Map()) {
  const myStore = createStoreWithMiddleware(rootReducer(history), initialState);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('./reducer', () => {
        // eslint-disable-next-line global-require,import/newline-after-import
        const nextRootReducer = require('./reducer');
        myStore.replaceReducer(nextRootReducer(history));
      });
    }
  }

  return { store: myStore, history };
}

export default store;
