import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import FlamelinkImage from '../util/flamelink_image';
import RootStyles, { Hero, HeroBG } from './root_styles';
import { Block, BlockHeading } from './block';
import ContactBlock from './contact_block';
import Header from './header';
import Footer from './footer';

const ServicesView = ({ services }) => {

  return (
    <RootStyles>
      <Hero size="small">
        <Header theme="dark" />
        <div className="HeroContent">
          <div className="HeroContentCenter">
            {services.get('heroHeading') && (
              <h1>{services.get('heroHeading')}</h1>
            )}
            {services.get('heroSubHeading') &&(
              <h2>{services.get('heroSubHeading')}</h2>
            )}
          </div>
        </div>
        <HeroBG size="small" style={{ backgroundImage: 'url("/images/hero_bg_1.jpg")' }} />
      </Hero>

      <Block className="sectionServices" size="full" padding>
        <Block size="full" wrapper>
          <Block size="half">
            
          </Block>
          <Block size="half">
            {services.get('contentDescription') && (
              <div dangerouslySetInnerHTML={{ __html: services.get('contentDescription') }} />
            )}
          </Block>
        </Block>
      </Block>

      <Block className="sectionStudios" size="full" padding>
        <BlockHeading align="center">The Studios</BlockHeading>
        <br />
        <br />
        <br />
        <Block size="full" wrapper>
          <Block size="half">
            <div className="studio">
              <img className="studioIcon" src="/images/icon-creative.svg" alt="" />
              <div>
                <h2>Branding & Creative</h2>
                <span>Branding, Design, Illustration, and Interactive</span>
              </div>
            </div>
            <div className="studio">
              <img className="studioIcon" src="/images/icon-hardware.svg" alt="" />
              <div>
                <h2>Hardware Development</h2>
                <span>Industrial Design, Engineering, and Manufacturing</span>
              </div>
            </div>
          </Block>
          <Block size="half">
            <div className="studio">
              <img className="studioIcon" src="/images/icon-marketing.svg" alt="" />
              <div>
                <h2>Growth & Marketing</h2>
                <span>Business Growth, Research, and Strategy</span>
              </div>
            </div>
            <div className="studio">
              <img className="studioIcon" src="/images/icon-software.svg" alt="" />
              <div>
                <h2>Software Engineering</h2>
                <span>Apps, Crypto, Mobile, Server, and Web</span>
              </div>
            </div>
          </Block>
        </Block>
      </Block>

      <ContactBlock />
      <Footer />
    </RootStyles>
  )
};

ServicesView.defaultProps = { services: new List() };
ServicesView.propTypes = { services: PropTypes.shape({}) };

export default ServicesView;
