import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import device from '../../common/media_queries';
import theme from '../../common/theme';

const HeaderNav = () => {
  const [drawer, toggleDrawer] = useState(false);

  const handleClick = () => {
    toggleDrawer(!drawer);
  };

  return (
    <HeaderNavStyles drawer={drawer}>
      <div className="nav-drawer">
        <Link to="/" onClick={handleClick}>Home</Link>
        <Link to="/team" onClick={handleClick}>Team</Link>
        <Link to="/services" onClick={handleClick}>Services</Link>
        <a href="#contact" onClick={handleClick}>Contact</a>
      </div>
      <div className="toggle" onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0}>Menu</div>
    </HeaderNavStyles>
  )};

const HeaderNavStyles = styled.nav`

  .nav-drawer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @media ${device.mobileOnly} {
      position: fixed;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 100vw;
      bottom: 0;
      left: 100vw;
      width: 100vw;
      height: 100vh;
      padding: ${theme.padding.base};
      background: ${theme.color.black};
      color: ${theme.color.text.light};
      z-index: 99;

      ${props => props.drawer === true && `
        right: 0;
        left: 0;
      `}
    }
  }

  a {
    color: ${theme.color.text.dark};
    font-family: 'Expressway Soft W00 Regular';
    text-decoration: none;
    font-size: 2vw;

    @media ${device.mobileOnly} {
      font-size: 32px;
      color: ${theme.color.text.light};
    }

    @media ${device.tablet} {
      margin-left: 2rem;
    }

    @media ${device.laptop} {
      font-size: 1.5vw;
    }

    @media ${device.desktop} {
      font-size: 1.15vw;
    }

    &:hover {
      color: ${theme.color.textActionHover};
    }
  }

  .toggle {
    display: none;

    @media ${device.mobileOnly} {
      display: block;
      position: absolute;
      top: ${theme.padding.small};
      right: ${theme.padding.base};
      z-index: 100;
      ${props => props.drawer === true && `
        color: ${theme.color.text.light};
      `}
    }
  }
`;

export default HeaderNav;
