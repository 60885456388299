// Firebase App is always required and must be first
import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyAsZfI4pXp8m_lwwvMuD7WDWwVUUM0uhn8',
  authDomain: 'prota-studios-web.firebaseapp.com',
  databaseURL: 'https://prota-studios-web.firebaseio.com',
  projectId: 'prota-studios-web',
  storageBucket: 'prota-studios-web.appspot.com',
  messagingSenderId: '1003778540601',
};

firebase.initializeApp(config);

export const db = firebase.database;

export default firebase;
