import styled from 'styled-components';
import theme from '../../common/theme';
import device from '../../common/media_queries';

export const FooterStyles = styled.div`
  padding: 4rem 0 5rem;
  font-size: 1em;
  color: ${theme.color.textLight};
  background-color: ${theme.color.bgDark};

  .text-right {
    text-align: right;
  }

  a {
    color: ${theme.color.action};
    text-decoration: none;

    &:hover {
      color: ${theme.color.actionHover};
    }
  }

  h2 {
    color: ${theme.color.blue};
  }

  .block-half {
    padding-bottom: 0 !important;
  }

  .topsection {
    padding-bottom: 5rem;

    > section {
      text-align: center;

      &:first-of-type {
        text-align: left;
      }

      &:last-of-type {
        text-align: right;
      }
    }
  }

  input {
    background: 0;
    border: 0;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    color: ${theme.color.whiteOffset};
    font-family: 'Expressway Soft W05 SemiBold', 'Proxima Nova', sans-serif;
    font-size: 17px;
    text-align: center;

    @media ${device.tablet} {
      text-align: initial;
    }

    &:placeholder {
      color: ${theme.color.whiteOffset};
    }
  }

  .font-small {
    font-size: 0.9rem;
    opacity: 0.5;
  }

  .text-left {
    text-align: center;
    margin-bottom: 1rem;

    @media ${device.laptop} {
      text-align: left;
      margin-bottom: initial;
    }
  }

  .text-right {
    text-align: center;

    @media ${device.laptop} {
      text-align: right;
    }
  }
`;

export default FooterStyles;
