import * as api from '../api/real_backend';
import { genericAPIActionTypes, genericAPIActionCreator } from '../../util';

const ACTIONS = {
  USERFORM: genericAPIActionTypes('ExampleApp:UserForm'),
  LIST_WIDGETS: genericAPIActionTypes('ExampleApp:ListWidgets'),
};

// Thunk to submit a comment for a venture's step
export const submitUserForm = (firstName, lastName) => genericAPIActionCreator(
  () => api.updateCurrentUser({ first_name: firstName, last_name: lastName }),
  ACTIONS.USERFORM,
  'Failed to update User',
);

export const listWidgets = genericAPIActionCreator(api.listWidgets, ACTIONS.LIST_WIDGETS, 'Unable to list widgets');

export default ACTIONS;
