import styled, { keyframes } from 'styled-components';
import theme from '../common/theme';
import device from '../common/media_queries';

export const bgHeroSlide = keyframes`
  from { background-position: 0 center; }
  to { background-position: -200vw center; }
`;

const RootStyles = styled.div`
  box-sizing: border-box;
  font-family: ${theme.font.sansSerif};

  * {
    box-sizing: inherit;
  }

  h2 {
    font-size: 28px;

    @media ${device.tablet} {
      font-size: 2vw;
    }
  }

  .blankList {
    list-style: none;
    padding: 0;

    li {
      img {
        width: 76px;
        height: 76px;
        margin-right: 2em;
      }
    }
  }

  .how {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: '';
      background-color: #ECF0F0;
      width: 75%;
      top: 2rem;
      right: 2rem;
      bottom: 7rem;
      z-index: -1;
    }

    section {
      padding-top: 4rem;
    }

    .blankList {
      margin-top: 6rem;
      margin-bottom: 3rem;
    }

    .noafter {
      &:after {
        display: none !important;
      }
    }
  }

  .sectionWho {
    position: relative;
    background-color: ${theme.color.white};

    .sectionInner {
      margin-top: 5rem;
    }

    .sectionBG {
      display: block;
      margin-top: -24vw;
      width: 100%;
    }

    .heading {
      margin-left: auto;
      margin-right: auto;
      max-width: 375px;

      @media ${device.tablet} {
        margin-left: initial;
        margin-right: initial;
        max-width: initial;
        text-align: center;
      }
    }
  }

  .sectionWho-description {
    margin: 0 auto;
    font-size: 16px;
    max-width: 375px;

    @media ${device.tablet} {
      columns: 22vw 2;
      column-gap: 2vw;
      font-size: 2vw;
      margin: 0 0 0 auto;
      max-width: calc((100vw - 10rem) / 3 * 2);

      p {
        margin-top: 0;
      }
    }

    @media ${device.laptop} {
      font-size: 1.6vw;
    }
  }

  .sectionHow {
    background-color: ${theme.color.bgDark};
    color: ${theme.color.textLight};

    &:after {
      content: '';
      display: block;
      width: 100vw;
      height: 36vw;
      background-image: url('images/bg-city.jpg');
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-top: 5vw;
      margin-left: -${theme.padding.small};
      margin-bottom: -${theme.padding.small};

      @media ${device.tablet} {
        margin-left: -${theme.padding.base};
        margin-bottom: -${theme.padding.base};
      }

      @media ${device.laptop} {
        margin-left: -${theme.padding.large};
        margin-bottom: -${theme.padding.large};
      }
    }

    .heading {
      margin-left: auto;
      margin-right: auto;
      max-width: 375px;

      @media ${device.tablet} {
        margin-left: initial;
        margin-right: initial;
        max-width: initial;
      }
    }

    p {
      opacity: 0.6;
      margin-left: auto;
      margin-right: auto;
      max-width: 375px;

      @media ${device.tablet} {
        margin-left: initial;
        margin-right: initial;
        max-width: initial;
      }
    }

    .sectionImage {
      display: block;
      margin: 2rem auto;
      width: 60%;

      @media ${device.tablet} {
        margin: initial;
        width: 80%;
      }
    }
  }

  .sectionHowContent {
    font-size: 16px;

    @media ${device.tablet} {
      font-size: 2vw;
    }

    @media ${device.laptop} {
      font-size: 1.6vw;
    }
  }

  .sectionServices {
    img {
      width: 100%;
    }

    p:first-of-type {
      margin-top: 0;
    }
  }

  .sectionStudios {
    background-color: ${theme.color.whiteOffset};

    .studio {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: ${theme.padding.large};
    }

    .studioIcon {
      width: 7vw;
      margin-right: ${theme.padding.base};
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0.25em;
    }
  }

  .capabilities {
    padding: 40vw 1rem 1rem 1rem;
    box-sizing: border-box;
    background-color: #EEEFF1;
    background-image: url('/images/bg_capabilities.jpg');
    background-size: 100vw auto;
    background-repeat: no-repeat;

    @media ${device.tablet} {
      padding: 40vw 4rem 4rem 4rem;
    }

    .card {
      box-sizing: border-box;
      padding: 2.5rem;
      background-color: transparent;
      width: 100%;

      @media ${device.tablet} {

      }
    }

    h2 {
      text-align: center;
      margin-bottom: 2rem;
    }

    .block-half {
      &:first-of-type {
        margin-right: 3rem;
      }

      &:last-of-type {
        margin-left: 3rem;
      }
    }
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    max-width: 34rem;
  }

  .list-title {
    margin-top: 0;
    margin-bottom: 0.5rem;

    + p:first-of-type {
      margin-top: 0.5rem;
    }
  }

  .team {
    position: relative;
    box-sizing: border-box;
    padding-top: 0;

    * {
      box-sizing: inherit;
    }

    .block-heading {
      position: absolute;
      display: inline-block;
      background-color: white;
      top: 0.75rem;
      left: 4rem;
    }
  }

  .team-inner {
    width: 100%;
  }

  .team-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1rem;
    width: 100%;
  }

  .teammate {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    text-decoration: none;

    @media ${device.mobileL} {
      width: 30vw;
    }

    @media ${device.laptop} {
      width: 20vw;
    }

    &:hover {
      .img {
        position: relative;

        &:after {
          background-color: ${theme.color.gold};
          border-radius: 50%;
          content: '';
          display: block;
          opacity: 0.5;
          position: absolute;
          top: 0; right: 0; bottom: 0; left: 0;
        }
      }

      .teammate-name,
      .teammate-role {
        color: ${theme.color.gold};
      }
    }

    .img {
      display: block;
      width: 30vw;
      height: 30vw;
      border-radius: 60px;
      object-fit: cover;

      @media ${device.mobileL} {
        width: 15vw;
        height: 15vw;
      }

      @media ${device.laptop} {
        width: 10vw;
        height: 10vw;
      }
    }
  }

  .teammate-name {
    margin: 0;
    text-align: center;
    color: ${theme.color.textDark};
  }

  .teammate-role {
    margin: 0;
    color: ${theme.color.textDark};
    font-size: 13px;
    text-align: center;
    opacity: 0.65;
  }

  .experience {
    margin-top: 2rem;
    margin-bottom: 5rem;

    * {
      box-sizing: border-box;
    }
  }

  .experienceNav {
    display: none;

    @media ${device.tablet} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    a {
      color: ${theme.color.action};
      cursor: pointer;
      display: inline-block;

      @media ${device.tablet} {
        display: block;
        font-size: 12px;
        margin: 1rem 0 2rem;
      }

      @media ${device.laptop} {
        font-size: initial;
      }

      &[data-status='active'] {
        font-family: 'Expressway Soft W05 SemiBold', 'Proxima Nova', sans-serif;
        color: ${theme.color.textDark};
        cursor: default;
      }
    }
  }

  .experienceGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 40%;
    overflow: hidden;
    position: relative;
    z-index: -1;

    @media ${device.tablet} {
      width:
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 200px;
      background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1) 100%);
      bottom: 0;
      pointer-events: none;
    }
  }

  .experienceItem {
    align-items: center;
    background-color: ${theme.color.white};
    border: 2px solid white;
    display: flex;
    height: 10%;
    justify-content: center;
    padding: 1vw;
    text-align: center;
    width: 50%;

    @media ${device.mobileL} {
      width: 33%;
    }

    @media ${device.tablet} {
      height: 25%;
      padding: 1rem 2rem;
      width: 25%;
    }

    &.text {
      font-family: 'Expressway Soft W05 SemiBold', 'Proxima Nova', sans-serif;
      height: 13vw;

      @media ${device.tablet} {
        font-size: 1.7vw;
        line-height: 1.2em;
        height: 9.05vw;
      }
    }

    img,
    .img {
      width: 100%;
    }

    .img {
      height: 0;
      padding-bottom: 38%
    }
  }

  .experienceGridToggle {
    text-align: center;
    width: 100%;
    color: ${theme.color.action};
    font-size: 14px;
    padding: 1rem;
    cursor: pointer;
    transition: all 200ms ease-in;

    &:hover {
      background: ${theme.color.whiteOffset};
      ${''}
    }
  }

  .contactus {
    background-color: ${theme.color.bgDark};
    background-image: url('/images/bg_map.jpg');
    background-size: cover;
    background-position: center top;
    box-sizing: border-box;
    color: ${theme.color.textLight};
    height: initial;

    @media ${device.tablet} {
      height: 50vw;
    }

    input[type='submit'] {
      margin-top: 2rem;
    }
  }
`;

export const Hero = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 60vw;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    display: block;
    height: ${props => (props.size === 'small' ? '30vw' : '60vw')};
  }

  &.HeroAutoHeight {
    height: auto;
  }

  h1 {
    position: relative;
    font-family: ${theme.font.serif};
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 0.0516rem;
    line-height: 1em;

    @media ${device.mobileL} {
      font-size: 6.25vw;
    }

    @media ${device.tablet} {
      margin-top: 1.15em;
      margin-left: -0.5vw;
      font-size: 6.25vw;
    }

    @media ${device.laptop} {
      font-size: 7vw;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 16px;
    text-align: center;

    @media ${device.mobileL} {
      font-size: 2.8vw;
    }

    @media ${device.tablet} {
      font-size: 2.05vw;
    }
  }

  .HeroContent {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    order: 3;

    @media ${device.tablet} {
      top: initial;
      left: initial;
      right: initial;
      bottom: initial;
    }
  }

  &.HeroAutoHeight .HeroContent {
    position: static;
  }

  .HeroContentInner {
    width: 100%;
    margin: 0 auto;
    padding: 0 ${theme.padding.small};

    @media ${device.tablet} {
      width: 100%;
      padding: 0 ${theme.padding.base};
    }

    @media ${device.laptop} {
      padding: 0 ${theme.padding.large};
    }

    h1, h2 {
      @media ${device.tablet} {
        max-width: 35vw;
      }
    }

    h1 {
      margin-top: 80px;
      margin-bottom: 2vw;
      max-width: 160px;
      text-align: left;

      @media ${device.mobileL} {
        max-width: 34vw;
      }

      &:after {
        content: '';
        display: block;
        position: relative;
        width: 135px;
        margin-left: 0.5vw;
        border-bottom: 2px solid ${theme.color.gold};

        @media ${device.mobileL} {
          width: 86%;
        }

        @media ${device.tablet} {
          width: 82%;
          border-bottom: 4px solid ${theme.color.gold};
        }

        @media ${device.laptop} {
          width: 91%;
          border-bottom: 8px solid ${theme.color.gold};
        }
      }
    }

    h2 {
      margin-top: 26vw;

      @media ${device.mobileL} {
        margin-top: 0;
        max-width: 40vw;
        padding: 0 2.8vw 0 0;
        text-align: left;
      }

      @media ${device.tablet} {
        margin-top: 0;
        max-width: 40vw;
        padding: 0 2.8vw 0 0;
        text-align: left;
      }
    }

    &.HeroContentInnerWide {
      h1, h2 {
        @media ${device.tablet} {
          max-width: 60vw;
        }
      }
    }
  }

  .HeroContentCenter {
    width: 100%;
    margin: 0 auto;
    padding: 0 ${theme.padding.small};

    @media ${device.mobileOnly} {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @media ${device.tablet} {
      width: 100%;
      padding: 0 ${theme.padding.base};
    }

    @media ${device.laptop} {
      padding: 0 ${theme.padding.large};
    }

    h1, h2 {
      text-align: center;
    }

    h1 {
      margin-bottom: 0;
    }
  }
`;

export const Content = styled.div`
  /* max-width: ${theme.siteWidth}; */
  margin: 0 auto;
  width: ${props => (props.noMobilePadding
    ? '100vw'
    : `calc(100vw - ${theme.padding.small} - ${theme.padding.small})`)
};

  @media ${device.tablet} {
    width: calc(100vw - ${theme.padding.base} - ${theme.padding.base});
  }
  @media ${device.laptop} {
    width: calc(100vw - ${theme.padding.large} - ${theme.padding.large});
  }
`;

export const bgherofade = keyframes`
  0% {
    opacity:1;
  }
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

export const HeroBG = styled.div`
  ${props => props.size === 'small' && `
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 40vw;
    background-size: cover;
    background-position: center;
    transform: scaleX(-1);
    filter: FlipH;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, transparent, white);
    }
  `}

  .bgWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; right: 0; bottom: 0; left: 0;
  }

  .bg1,
  .bg2 {
    position: absolute;
    top: 0;
    transform: translateY(0px);
    height: 60vw;
    width: 100vw;

    img {
      width: 100%;
    }
  }
`;

export default RootStyles;
