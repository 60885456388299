import React from 'react';
import { Block } from '../block';
import { Content } from '../root_styles';
import FooterStyles from './footer_styles';

const Footer = () => (
  <FooterStyles>
    <Content>
      <Block className='font-small' size='full' wrapper>
        &copy; {new Date().getFullYear()} Prota Studios, LLC. A&nbsp;<a href='https://www.protaventures.com'>Prota Ventures</a>&nbsp;Company. All Rights Reserved.&nbsp;&nbsp;&middot;&nbsp;&nbsp;
        <a href="/privacy">Privacy Policy</a>
        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
        <a href="/cookie">Cookie Policy</a>
      </Block>
    </Content>
  </FooterStyles>
)

export default Footer;
