import { Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as ParamsContext from './params_context';
import RR from './resourceful_reducer';

const DEFAULT_STATE = new Map();

export function combineImmutableReducers(reducers, initialState = DEFAULT_STATE) {
  // redux-immutable is lame and changed the second param type to a generator instead of the actual default...
  return combineReducers(reducers, () => initialState);
}

// Simple little helper that wraps setTimeout in a Promise
export const sleep = (duration, ...args) => new Promise(resolve => setTimeout(() => resolve(...args), duration));

export const { withParams, exportingParams } = ParamsContext;

export const ResourcefulReducer = RR;

export const genericAPIActionTypes = namespace => ({
  request: `${namespace}:REQUEST`,
  success: `${namespace}:SUCCESS`,
  failure: `${namespace}:FAILURE`,
});

export const genericAPIActionCreator = (action, actionTypes, errorMessage, meta = {}) => (dispatch) => {
  dispatch({ type: actionTypes.request, ...meta });
  return action().then(
    response => dispatch({ type: actionTypes.success, response, ...meta }),
    error => dispatch({ type: actionTypes.failure, message: error.message || errorMessage, ...meta }),
  );
};
