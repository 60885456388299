import styled, { css } from 'styled-components';
import theme from '../../common/theme';
import device from '../../common/media_queries';
import { Content } from '../root_styles';

const Block = styled.section`

  padding: ${props => (props.padding ? theme.padding.small : '0')};
  position: relative;
  width: 100%;

  @media ${device.tablet} {
    padding: ${props => (props.padding ? theme.padding.base : '0')};

    ${props => props.size === 'full' && css`
      width: 100%;
    `}

    ${props => props.size === 'half' && css`
      margin: 0 ${theme.padding.base};
      width: calc((100% - ${theme.padding.base} - ${theme.padding.base}) / 2);
      &:first-of-type { margin-left: 0; }
      &:last-of-type { margin-right: 0; }
    `}

    ${props => props.size === 'third' && css`
      margin: 0 ${theme.padding.base};
      width: calc((100% - ${theme.padding.base} - ${theme.padding.base} - ${theme.padding.base} - ${theme.padding.base}) / 3);
      &:first-of-type { margin-left: 0; }
      &:last-of-type { margin-right: 0; }
    `}
  }

  @media ${device.laptop} {
    padding: ${props => (props.padding ? theme.padding.large : '0')};
  }

  ${props => props.wrapper && css`
    @media ${device.tablet} {
      display: flex;
    }

    ${Content} {
      @media ${device.tablet} {
        display: flex;
        max-width: calc(${theme.siteWidth} + 4rem);
        padding-left: ${theme.padding.base};
        padding-right: ${theme.padding.base};
      }

      ${Block} {

        @media ${device.tablet} {
          position: initial;

          &:first-of-type { padding-left: 0; }
          &:last-of-type { padding-right: 0; }

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: -1;
            width: 50vw;
          }
        }
      }
    }
  `}

  ${props => props.background && css`
    &:first-of-type {
      &:after {
        left: 0;
        right: 50vw;
      }
    }

    &:last-of-type {
      &:after {
        left: 50vw;
        right: 0;
      }
    }
  `}

  ${props => props.background === 'dark' && css`
    color: ${theme.color.textLight};
    background-color: #14242C;
    &:after { background-color: #14242C; }

    h2 {
      color: ${theme.color.secondaryLight};
    }
  `}

  ${props => props.background === 'light' && css`
    color: ${theme.color.textDark};
    background-color: #F6F3F0;
    &:after { background-color: #F6F3F0; }
    h2 {
      color: ${theme.color.secondary};
    }
  `}

`;

export default Block;
