import { Record } from 'immutable';

const DEFAULT_STATE = {
  first_name: undefined,
  last_name: undefined,
  uid: undefined,
};

class UserModel extends Record(DEFAULT_STATE) {
  static _name = 'Common::UserModel';

  get name() {
    return `${this.first_name} ${this.last_name}`;
  }
}

export default UserModel;
