import { reducer as FormReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { combineImmutableReducers } from './util';
import SessionReducer, * as SessionSelect from './session/reducer';
import ExampleAppReducer, * as ExampleAppSelectors from './example_app/reducer';
import GlobalSettingsReducer, * as GlobalSettingsSelectors from './global_settings/reducer';
import ACTIONS from './actions';
import HubSpotFormReducer, { getContactFormStatus } from './hub_spot_form/reducer';
import { ContentReducer, ContentSelectors } from './vendor/flamelink';

const defaultGridLimit = 12;
function clientGridLimitReducer(state = defaultGridLimit, action) {
  switch (action.type) {
  case ACTIONS.TOGGLE_CLIENT_GRID_LIMIT:
    return state === defaultGridLimit ? -1 : defaultGridLimit;
  case ACTIONS.SHOW_CLIENT_GRID_ALL:
    return state === defaultGridLimit ? -1 : -1;
  default:
    return state;
  }
}

const defaultGridButton = false;
function clientGridButtonReducer(state = defaultGridButton, action) {
  switch (action.type) {
  case ACTIONS.TOGGLE_CLIENT_GRID_LIMIT:
    return state === defaultGridButton ? true : defaultGridButton;
  case ACTIONS.SHOW_CLIENT_GRID_ALL:
    return state === defaultGridButton ? true : true;
  default:
    return state;
  }
}

const Reducer = history => combineImmutableReducers({
  router: connectRouter(history),
  content: ContentReducer,
  form: FormReducer,
  session: SessionReducer,
  exampleApp: ExampleAppReducer,
  globalSettings: GlobalSettingsReducer,
  hubSpotForm: HubSpotFormReducer,
  gridLimit: clientGridLimitReducer,
  gridButton: clientGridButtonReducer,
});

export default Reducer;

export const getGridLimit = state => state.get('gridLimit');
export const getGridButton = state => state.get('gridButton');

// Flamelink Content
export const getContent = (state, schema) => ContentSelectors.getContent(state.get('content'), schema);
export const getContentBySlug = (state, schema, slug) => ContentSelectors.getContentBySlug(state.get('content'), schema, slug);
export const getContentByID = (state, schema, id) => ContentSelectors.getContentByID(state.get('content'), schema, id);

// HubSpot Selectors
export const getHubSpotContactFormStatus = state => getContactFormStatus(state.get('hubSpotForm'));

export const getExampleAppFormStatus = state => ExampleAppSelectors.getFormStatus(state.exampleApp);
export const getWidgets = state => ExampleAppSelectors.getWidgets(state.exampleApp);
export const getGlobalSetting = (state, key, notSetValue = undefined) => GlobalSettingsSelectors.getSetting(state.globalSettings, key, notSetValue);
export const getFlashList = state => SessionSelect.getFlashList(state.session);
export const getCurrentUser = state => SessionSelect.getCurrentUser(state.session);
