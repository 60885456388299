import styled, { css } from 'styled-components';
import theme from '../../common/theme';
import device from '../../common/media_queries';

const BlockHeading = styled.div`
  font-family: ${theme.font.serif};
  font-size: 28px;
  font-weight: normal;
  letter-spacing: 0.0516rem;
  line-height: 1em;
  margin: 0;
  text-align: ${props => (props.align ? props.align : 'initial')};

  @media ${device.mobileL} {
    font-size: 6vw;
    margin-bottom: 4vw;
  }

  @media ${device.tablet} {
    font-size: 7vw;
    margin-bottom: 3vw;
  }
`;

export default BlockHeading;
