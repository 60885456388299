import React from 'react';
import { Block, BlockHeading } from '../block';
import ContactForm from '../../hub_spot_form/components/contact_form';

const ContactBlock = () => (
  <Block className='contactus' size='full' padding id="contact">
    <BlockHeading>Let’s work together.</BlockHeading>

    <Block size='full' wrapper>
      <Block size='half' id='hireUs'>
        <ContactForm />
      </Block>
    </Block>
  </Block>
);

export default ContactBlock;
