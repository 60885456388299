import styled from 'styled-components';
import theme from '../../common/theme';
import device from '../../common/media_queries';

const HeaderStyles = styled.header`
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding: 1rem ${theme.padding.small};
  width: 100%;
  z-index: 100;

  @media ${device.mobileOnly} {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.mobileL} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.tablet} {
    padding: 2rem ${theme.padding.base}
  }

  @media ${device.laptop} {
    padding: 2rem ${theme.padding.large};
  }

  nav {
    @media ${device.mobileL} {
      text-align: right;
    }
  }

  ${props => props.theme === 'light' && `
    nav a {
      color: ${theme.color.textLight};
    }
  `}
`;

export default HeaderStyles;
