import { fromJS, Record } from 'immutable';
import { combineReducers } from 'redux-immutable';

const EMPTY_MAP = fromJS({});
const EMPTY_LIST = fromJS([]);
const DEFAULT_STATE = Record({ byId: {}, allIds: [], lastUpdated: undefined });

const getOne = (state, id) => state.getIn(['byId', id]);
const getList = (state, list = state.get('allIds')) => list.map(id => getOne(state, id));
const getLastUpdated = state => state.get('lastUpdated');

export default function ResourcefulReducer(resourceName, { actionTypes } = {}) {
  const actionTypeList = fromJS(actionTypes || [`GET_${resourceName.toUpperCase()}_SUCCESS`]);

  const byId = (state = EMPTY_MAP, action) => {
    if (action.response && action.response.entities && action.response.entities[resourceName]) {
      return state.merge(fromJS(action.response.entities[resourceName]));
    }
    return state;
  };

  const allIds = (state = EMPTY_LIST, action) => {
    if (actionTypeList.includes(action.type)) {
      return fromJS(action.response.result);
    }
    return state;
  };

  const lastUpdated = (state = false, action) => {
    if (actionTypeList.includes(action.type)) {
      return Date.now();
    }
    return state;
  };

  const reducer = combineReducers({ byId, allIds, lastUpdated }, DEFAULT_STATE);
  reducer.selectors = { getOne, getList, getLastUpdated };
  return reducer;
}
